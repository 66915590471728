import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import Prism from "prismjs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Message = ({ message, onClear, onCopy }) => {
  const { text, sender, timestamp } = message;
  const [profilePicture, setProfilePicture] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [copied, setCopied] = useState(false);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  const processText = (text) => {
    if (!text) return "";

    const parts = text.split(/(\*\*[^*]+\*\*|^[0-9]+\.\s.*|^-\s.*)/gm);
    const formattedParts = [];

    parts.forEach((part, index) => {
      if (/\*\*[^*]+\*\*/.test(part)) {
        formattedParts.push(
          <h2
            key={index}
            className="lg:text-base text-base font-semibold mt-2 mb-1"
          >
            {part.replace(/\*\*/g, "").trim()}
          </h2>
        );
      } else if (/^[0-9]+\.\s/.test(part)) {
        formattedParts.push(
          <li key={index} className="ml-5 list-decimal">
            {part.trim()}
          </li>
        );
      } else if (/^-\s/.test(part)) {
        formattedParts.push(
          <li key={index} className="ml-5 list-disc">
            {part.trim()}
          </li>
        );
      } else {
        formattedParts.push(
          <span key={index} className="block text-base leading-relaxed mt-1">
            {part.trim()}
          </span>
        );
      }
    });

    return formattedParts;
  };

  const copyToClipboard = (code) => {
    navigator.clipboard
      .writeText(code)
      .then(() => {
        toast.success("Code copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        toast.error("Failed to copy code.");
      });
  };

  const handleCopy = () => {
    onCopy(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  useEffect(() => {
    const email = localStorage.getItem("userEmail");
    const token = localStorage.getItem("authToken");

    if (email) {
      setIsLoggedIn(true);
      setUserEmail(email);

      const fetchUserProfile = async () => {
        try {
          const response = await fetch(
            `https://convogenius-backend-production.up.railway.app/api/v1/${email}/user`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (!response.ok) {
            throw new Error("Failed to fetch user profile");
          }

          const result = await response.json();

          if (result.status === 200 && result.data) {
            const base64Image = result.data.ProfileImage;
            const profileImageUrl = base64Image
              ? `data:image/jpeg;base64,${base64Image}`
              : "https://via.placeholder.com/150";

            setProfilePicture(profileImageUrl);
            localStorage.setItem("profilePicture", profileImageUrl);
          } else {
            console.error("User data not found or invalid status.");
            setProfilePicture("https://via.placeholder.com/150");
          }
        } catch (error) {
          console.error("Error fetching profile picture:", error);
          setProfilePicture("https://via.placeholder.com/150");
        }
      };

      fetchUserProfile();
    }
  }, []);

  return (
    <div
      className={`flex ${
        sender === "user" ? "justify-end" : "justify-start"
      } mb-4`}
    >
      {sender !== "user" && (
        <img
          src="images/logo.png"
          alt="Convogenius Profile"
          className="w-8 h-8 border border-gray-500 rounded-full mr-2"
        />
      )}
      <div
        className={`max-w-md w-full ${
          sender === "user" ? "ml-auto" : "mr-auto"
        }`}
      >
        <div
          className={`rounded-lg shadow ${
            sender === "user"
              ? "bg-blue-600 text-white"
              : "bg-gray-300 text-gray-900"
          } py-3 px-4 break-words`}
        >
          {processText(text)}
        </div>
        <div className="text-xs text-gray-500 mt-1 flex justify-end items-center space-x-2">
          <span>{formatDate(timestamp)}</span>
          <button
            onClick={handleCopy}
            className="text-gray-700 hover:text-gray-780 transition-colors focus:outline-none"
            aria-label="Copy message"
          >
            <FontAwesomeIcon icon={faCopy} />
          </button>
          <button
            onClick={onClear}
            className="text-red-700 hover:text-red-800 transition-colors focus:outline-none"
            aria-label="Delete message"
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </button>
        </div>
        {copied && <div className="text-xs text-green-500 mt-1">Copied!</div>}
      </div>
      {sender === "user" && (
        <img
          src={profilePicture}
          alt="User Profile"
          className="w-8 h-8 border border-gray-500 rounded-full ml-2"
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default Message;
